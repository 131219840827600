body {
  background-image: -moz-linear-gradient(top , transparent, transparent 39px, #ffe3f5 0px);
  background-image: -webkit-linear-gradient(top , transparent, transparent 39px, #ffe3f5 0);
  -webkit-background-size:  100% 40px;
  background-size: 100% 40px;

  padding: 40px 0;
}

button, a {
  cursor: pointer;
}

.brush {
  font-family: 'Caveat Brush', sans-serif;
}

.card-shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(180,180,180,1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(180,180,180,1);
  box-shadow: 0px 0px 10px 0px rgba(180,180,180,1);
}
